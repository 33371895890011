@use "sass:math";

// Variables de couleur pour le menu
$navbar-bg: linear-gradient(to bottom, #2c2c2c, #4a4a4a, #6b6b6b); // Dégradé du noir au gris clair
$navbar-shadow: rgba(0, 0, 0, 0.25); // Ombre portée subtile
$navbar-border: rgba(255, 255, 255, 0.15); // Bordure discrète
$link-color: #f0f0f0; // Couleur du texte des liens
$link-hover-bg: rgba(255, 255, 255, 0.2); // Fond des liens au survol avec une légère transparence
$link-hover-color: #ffffff; // Couleur du texte au survol

// Dégradé de fond de la barre de navigation
.navbar {
    background: $navbar-bg;
    box-shadow: 0 2px 8px $navbar-shadow; // Ombre douce pour l'effet de profondeur
    padding: 1rem 2rem;
    border-radius: 12px; // Bord arrondi plus marqué
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid $navbar-border; // Bordure discrète
}

// Marque de navigation
.navbar-brand {
    color: $link-color;
    font-weight: bold;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: color 0.3s ease;
}

// Liens de navigation
.navbar-nav {
    display: flex;
    gap: 20px;
}

.navbar-nav .nav-link {
    color: $link-color;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    position: relative;
    overflow: hidden;
    background-color: transparent; // Assure que le fond est transparent par défaut
}

.navbar-nav .nav-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $link-hover-bg;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
    z-index: 0;
}

.navbar-nav .nav-link:hover::before {
    transform: scaleX(1);
}

.navbar-nav .nav-link:hover {
    color: $link-hover-color; // Couleur du texte au survol
    z-index: 1;
}

// Icônes de la barre de navigation
.navbar-toggler-icon {
    background-image: url('data:image/svg+xml;charset=utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="#f0f0f0" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
    width: 30px;
    height: 30px;
    filter: brightness(0.8); // Réduction de la luminosité pour une meilleure visibilité
}

.navbar-toggler:hover .navbar-toggler-icon {
    filter: brightness(0.6); // Réduction plus prononcée de la luminosité au survol
}

// Responsive pour petits écrans
@media (max-width: 720px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-nav {
        flex-direction: column;
        width: 100%;
    }

    .navbar-nav .nav-link {
        width: 100%;
        text-align: center;
        padding: 1rem;
        margin: 0;
    }
}
