@use "sass:math";

// Variables de couleur pour un thème en noir et blanc
$background-main: #f4f4f4; /* Fond gris clair pour un contraste plus doux */
$background-dark: #1e1e1e; /* Fond sombre pour les sections */
$highlight-color: #333333; /* Gris foncé pour les éléments interactifs */
$text-color: #212121; /* Texte en gris très foncé pour une bonne lisibilité */
$border-color: #dcdcdc; /* Gris clair pour les bordures et les détails */
$shadow-light: rgba(0, 0, 0, 0.1); /* Ombre légère pour les éléments */
$shadow-dark: rgba(0, 0, 0, 0.3); /* Ombre plus prononcée pour les éléments au survol */

// Taille de base pour les éléments
$base-size: 16px;
$base-padding: 20px;
$border-radius: 8px;
$transition-duration: 0.3s;

// Style global
body {
    background: $background-main;
    color: $text-color;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    margin: 0;
    position: relative;
    height: 100vh;
    line-height: 1.6;
    font-size: $base-size;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.1); /* Subtle inset shadow for depth */
}

// Style pour le conteneur principal
.base-container {
    display: flex;
    gap: 30px;
    margin: 0 auto;
    padding: 60px;
    background-color: $background-dark;
    border-radius: $border-radius;
    box-shadow: 0 10px 20px $shadow-dark;
    position: relative;
    width: 80%;
    overflow: hidden;
    border: 1px solid $border-color;
}

// Style pour les sections
.base-section {
    padding: 40px;
    border-radius: $border-radius;
    background-color: $background-main;
    box-shadow: 0 8px 15px $shadow-light;
    transition: transform $transition-duration ease, background-color $transition-duration ease, box-shadow $transition-duration ease;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $border-color;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 25px $shadow-dark;
    }
}

// Style des en-têtes
.base-section-header {
    font-size: 24px;
    margin-bottom: 15px;
    color: $highlight-color;
    text-transform: uppercase;
    border-bottom: 2px solid $highlight-color;
}

// Style pour les sous-en-têtes
.base-section-subheader {
    font-size: 20px;
    margin-bottom: 15px;
    color: lighten($highlight-color, 10%);
}

// Style pour les listes
.base-list {
    list-style-type: none;
    padding: 0;
}

// Style pour les éléments de liste
.base-item {
    margin-bottom: 20px;
    padding: $base-padding;
    border-radius: $border-radius;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 10px $shadow-light;
    transition: transform $transition-duration ease, box-shadow $transition-duration ease;
    border: 1px solid $border-color;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px $shadow-dark;
    }
}

// Style pour les titres des éléments
.base-item-title {
    font-size: 18px;
    font-weight: bold;
    color: $highlight-color;
}

// Style pour les descriptions des éléments
.base-item-description {
    font-size: 16px;
    color: $text-color;
}

// Responsive ajusté pour mobile
@media screen and (max-width: 768px) {
    .base-container {
        flex-direction: column;
        padding: 30px;
    }

    .base-item {
        padding: 15px;
    }
}

// Style pour les images des éléments de liste
.base-item-image {
    width: 150px;
    height: 150px;
    margin-right: 15px;
    vertical-align: middle;
    filter: drop-shadow(0 0 8px $shadow-light);
    border-radius: 50%;
    object-fit: cover;
}

// Style pour les images générales
img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px $shadow-light;
    transition: transform $transition-duration ease, box-shadow $transition-duration ease;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 10px 20px $shadow-dark;
    }
}

// Effet de parallaxe subtil pour le fond
body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    z-index: -1;
    background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.2));
}

// Animation des étoiles pour un effet de profondeur
@keyframes starTwinkle {
    0%, 100% {
        opacity: 0.5;
        transform: scale(1);
    }
    50% {
        opacity: 0.8;
        transform: scale(1.2);
    }
}
