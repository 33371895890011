.footer-flex-container {
    display: flex;
    flex-wrap: wrap; // Permet aux éléments de passer à la ligne si l'espace est insuffisant
    justify-content: space-around; // Espace entre les éléments
    gap: 20px; // Espace entre les éléments
}

.footer-item {
    flex: 1; // Chaque élément prend un espace égal
    min-width: 200px; // Largeur minimale pour éviter que les éléments ne deviennent trop petits
    text-align: center; // Centrer le contenu de chaque élément

    h4 {
        margin-bottom: 10px;
    }

    .footer-item-description {
        margin-top: 10px;
        color: #ffffff;
    }

    a{
        color: #ffffff;
    }
}

.footer-copyright {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #777;
    padding: 10px 0;
    border-top: 1px solid #ddd; // Ligne de séparation avec les éléments au-dessus
}
