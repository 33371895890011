@use "sass:math";
@import 'base.scss'; // Importer le fichier base.scss si nécessaire

// Variables de couleur pour un thème en noir et blanc
$portfolio-bg: #1e1e1e; // Fond pour le conteneur du portfolio
$portfolio-item-bg: rgba(255, 255, 255, 0.1); // Fond transparent pour les éléments du portfolio
$portfolio-title-color: #e0e0e0; // Couleur des titres des éléments
$portfolio-description-color: #b0b0b0; // Couleur des descriptions
$portfolio-shadow-light: rgba(0, 0, 0, 0.15); // Ombre légère pour les éléments
$portfolio-shadow-dark: rgba(0, 0, 0, 0.4); // Ombre plus prononcée pour les éléments au survol

// Style pour le conteneur du portfolio
.portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: $portfolio-bg;
    border-radius: 20px;
    box-shadow: 0 10px 20px $portfolio-shadow-dark;
    width: 85%;
    margin: 30px auto;
}

// Style pour l'en-tête du portfolio
.portfolio-header {
    font-size: 26px;
    text-transform: uppercase;
    color: #ffffff; // Couleur blanche pour le texte de l'en-tête
    margin-bottom: 25px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); // Ombre portée pour un effet de profondeur
}

// Grille des éléments de portfolio
.portfolio-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
}

// Style pour les éléments de portfolio
.portfolio-item {
    background: $portfolio-item-bg;
    padding: 25px;
    width: calc(33.333% - 30px); // Trois colonnes
    border-radius: 15px;
    box-shadow: 0 8px 15px $portfolio-shadow-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2); // Bordure subtile

    &:hover {
        transform: translateY(-5px); // Moins de mouvement pour un effet plus subtil
        box-shadow: 0 12px 24px $portfolio-shadow-dark; // Ombre plus prononcée au survol
    }
}

// Style pour le titre des éléments de portfolio
.portfolio-title {
    font-size: 20px;
    color: $portfolio-title-color;
    margin-bottom: 10px;
}

// Style pour les descriptions des éléments de portfolio
.portfolio-description {
    font-size: 16px;
    color: $portfolio-description-color;
    margin-bottom: 20px;
    line-height: 1.5;
}

// Bouton de lien
button.ant-btn-primary {
    margin-top: auto;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    background-color: #333; // Couleur de fond pour le bouton
    color: #fff; // Couleur du texte pour le bouton

    &:hover {
        background-color: #444; // Couleur de fond au survol
    }
}

// Style pour les images des éléments de portfolio
.portfolio-item img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 10px $portfolio-shadow-light;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.03); // Augmentation légère de l'échelle pour plus de sobriété
        box-shadow: 0 8px 15px $portfolio-shadow-dark;
    }
}

// Media query pour les écrans réduits
@media screen and (max-width: 768px) {
    .portfolio-item {
        width: calc(100% - 30px); // Ajustement en une seule colonne
    }
}
