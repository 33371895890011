@use "sass:math";


// Variables de couleur pour le contact
$contact-bg: rgba(0, 0, 0, 0.05); // Dégradé sombre du noir au gris
$contact-item-bg:rgba(0, 0, 0, 0.05) ; // Gris très clair pour les éléments de contact
$contact-item-hover-bg: #f4f4f4; // Gris clair avec une légère modification au survol
$contact-item-shadow-light: rgba(0, 0, 0, 0.1); // Ombre plus douce
$contact-item-shadow-dark: rgba(0, 0, 0, 0.3); // Ombre plus prononcée
$link-color: #070404; // Couleur des liens
$link-hover-color: #050404; // Couleur des liens au survol
$base-padding: 20px;
$border-radius: 10px;
$transition-duration: 0.3s;


/* Conteneur des éléments de contact */
.contact-flex-container {
    display: flex;
    flex-wrap: wrap; // Organisation fluide sur plusieurs lignes si nécessaire
    justify-content: center; // Centre les éléments dans la flexbox
    gap: 30px; // Espace accru entre les éléments pour plus de clarté
    padding: 30px;
    border-radius: 20px; // Coins arrondis pour le conteneur
    box-shadow: 0 12px 24px $contact-item-shadow-light; // Ombre portée douce et plus large
}

/* Section de base pour les éléments de contact */
.base-section-contact {
    border-radius: 20px;
    background-color: $contact-item-bg;
    box-shadow: 0 8px 16px $contact-item-shadow-light;
    transition: transform 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Style pour chaque élément de contact */
.contact-item {
    flex: 1 1 280px; // Largeur flexible avec une taille minimale de 280px pour plus de responsivité
    max-width: 360px; // Limite la largeur maximale
    text-align: center;
    background-color: $contact-item-bg;
    padding: 30px; // Augmentation du padding pour plus de confort
    border-radius: 15px; // Coins légèrement plus arrondis pour un effet plus doux
    color: #000000; // Couleur du texte claire et professionnelle
    box-shadow: 0 6px 12px $contact-item-shadow-light; // Ombre plus douce
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px); // Légère translation pour un effet d'élévation plus marqué
        background-color: $contact-item-hover-bg; // Changement de couleur de fond au survol
        box-shadow: 0 12px 24px $contact-item-shadow-dark; // Ombre plus prononcée au survol
    }

    /* Description de l'élément */
    .contact-item-description {
        margin-top: 20px; // Augmentation de l'espacement au-dessus pour plus d'aération
        font-size: 18px; // Taille de la police légèrement augmentée pour une meilleure lisibilité
        color: #706b6b; // Texte légèrement grisé pour un effet plus professionnel
        line-height: 1.6;
        max-width: 80%; // Limite la largeur pour une meilleure lisibilité
    }

    /* Style pour les liens */
    a {
        margin-top: 15px; // Espacement accru au-dessus pour plus d'aération
        color: $link-color; // Couleur des liens
        text-decoration: none;
        font-weight: 600; // Police légèrement plus épaisse pour plus de visibilité

        &:hover {
            text-decoration: underline;
            color: $link-hover-color; // Couleur des liens au survol
        }
    }
}

/* Media queries pour les petits écrans */
@media screen and (max-width: 768px) {
    .contact-item {
        flex: 1 1 100%; // Les éléments prennent toute la largeur disponible
        max-width: 100%; // Suppression de la limite de largeur maximale pour mobile
    }
}
