@use "sass:math";

/* Variables de couleur pour le thème en dégradés gris clairs */
$form-bg: linear-gradient(135deg, #f0f0f0, #d9d9d9); /* Dégradé gris clair pour le fond du formulaire */
$input-bg: linear-gradient(135deg, #e0e0e0, #c8c8c8); /* Dégradé gris moyen pour les champs d'entrée */
$input-text-color: #333; /* Texte sombre pour les champs d'entrée */
$label-color: #666; /* Gris moyen pour les labels */
$border-color: #b0b0b0; /* Bordure gris clair pour les champs */
$error-color: #ff4d4d; /* Rouge clair pour les messages d'erreur */
$button-bg: linear-gradient(135deg, #c0c0c0, #a0a0a0); /* Dégradé gris clair à moyen pour le bouton */
$button-text: #000; /* Texte noir pour le bouton */
$button-hover-bg: linear-gradient(135deg, #d0d0d0, #b0b0b0); /* Dégradé légèrement plus clair au survol */
$button-active-bg: linear-gradient(135deg, #a0a0a0, #909090); /* Dégradé gris moyen au clic */

/* Style pour le conteneur du formulaire */
.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 12px;
  background: $form-bg; /* Dégradé gris clair pour le fond du formulaire */
  color: $input-text-color; /* Texte sombre */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Ombre plus douce pour le conteneur */
}

/* Style pour le titre du formulaire */
.form-title {
  font-size: 36px;
  margin-bottom: 32px;
  color: $label-color; /* Gris moyen pour le titre */
  text-align: center;
  text-transform: uppercase; /* Majuscules pour un effet plus dynamique */
  letter-spacing: 1px; /* Espacement des lettres */
}

/* Style pour les labels des champs */
.form-item-label {
  font-size: 18px;
  color: $label-color; /* Gris moyen pour les labels */
  margin-bottom: 8px; /* Espacement sous le label */
  display: block; /* Assure que le label occupe toute la largeur */
}

/* Style pour les champs de saisie */
.input-field {
  width: calc(100% - 32px); /* Ajustement de la largeur */
  padding: 16px;
  margin-bottom: 32px;
  border: 2px solid $border-color; /* Bordure gris clair */
  border-radius: 8px;
  font-size: 18px;
  color: $input-text-color; /* Texte sombre pour les champs */
  background: $input-bg; /* Dégradé gris moyen */
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: darken($border-color, 10%); /* Bordure légèrement assombrie pour focus */
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2); /* Ombre légère */
  }
}

/* Style pour les messages d'erreur */
.error-message {
  color: $error-color;
  font-size: 14px;
  margin-top: 8px;
}

/* Style pour le conteneur de case à cocher */
.checkbox-container {
  margin-top: 24px;
}

/* Style pour le bouton de soumission */
.submit-button {
  width: 100%;
  border: none;
  border-radius: 8px;
  background: $button-bg; /* Dégradé gris clair pour le bouton */
  color: $button-text; /* Texte noir pour le bouton */
  font-size: 20px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: $button-hover-bg; /* Dégradé légèrement plus clair au survol */
  }

  &:active {
    background: $button-active-bg; /* Dégradé gris moyen au clic */
  }
}
